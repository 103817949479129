<template>
  <div class="notice-container">
    <el-row>
      <el-col class="notice-left" :span="17">
        <div class="content">
          <div class="notice-breadcrumb">
            <div>首页</div>
            <div>></div>
            <div>公告</div>
            <div>></div>
            <div class="last-text">公告详情</div>
          </div>
          <div class="notice-title">杭州里德信息化数据对接项目正式启动</div>
          <div class="notice-time">
            <div>发布时间： 2020/04/28 15:47:13</div>
            <div>浏览： 56次</div>
          </div>
          <p>
            4月23日下午，里德与客户信息化数据对接项目启动会议在杭州成功举行，里德组建了以查德芳副总为组长的里德生产数据对接小组，负责项目中里德资源协调及进度管控。
          </p>
          <p style="margin-top: 32px">
            会议本着数据互联互通,促进数字化经营的理念，针对内部系统改造和外部数据获取方面双方交换了意见。在业务及IT对接方面进行了初步沟通，在数据报表以及生产批次管理方面做了进一步探讨。
          </p>
          <p style="margin-top: 32px">
            随着对数据精细化管理的要求不断提高，此次信息化数据对接的项目将极大的便利公司之间信息化数据的交换，能有效避免手工操作带来的误差影响,同时对提升双方数据的统一性、及时性以及准确度起到了积极推动作用。本次合作将进一步提升里德生产与管理水平，为里德生产经营再添动力！
          </p>
          <div class="notice-footer">
            <div class="notice-prev">
              <router-link tag="span" to="/notice2">
                < 上一篇</router-link>
            </div>
            <div class="notice-next">下一篇 ></div>
          </div>
        </div>
      </el-col>
      <el-col class="notice-right" :span="7">
        <div class="content">
          <div class="title">最新公告</div>
          <div>
            <div class="notice-other-1 pointer">
              <router-link tag="span" to="/notice14">童年，是一只载着无数快乐的小船；童年,……</router-link>
            </div>
            <div class="notice-other-time-1">2022-06-01 15:26</div>

            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice13">小鸟为天空而歌，蜜蜂为花朵而歌，今天……</router-link>
            </div>
            <div class="notice-other-time-2">2022-05-01 10:39</div>
            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice12">为助力健康亚运建设，保障劳动者职业健……</router-link>
            </div>
            <div class="notice-other-time-2">2022-04-26 16:52</div>
            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice11">2022年3月24日，杭州里德通信有限公司桐……</router-link>
            </div>
            <div class="notice-other-time-2">2022-04-25 11:26</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';

.notice-container {
  padding: 24px gap() 56px;
}

.notice-left {
  .content {
    background-color: #fff;
    margin-right: 12px;
    padding: 16px 32px 40px;
    font-size: 16px;
  }

  p {
    text-indent: 2em;
    line-height: 1.5;
  }
}

.notice-breadcrumb {
  display: flex;
  font-size: 14px;
  margin-bottom: 20px;

  .last-text {
    color: #a6a6a6;
  }
}

.notice-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.notice-time {
  display: flex;
  color: #a6a6a6;
  font-size: 14px;
  padding-bottom: 8px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d4d4d4;

  div:first-of-type {
    margin-right: 32px;
  }
}

.notice-img-1 {
  margin-top: 16px;
  margin-bottom: 32px;
  text-align: center;

  img {
    &:first-of-type {
      margin-bottom: 16px;
    }
  }
}

.notice-img-3 {
  img {
    display: block;
    margin: auto;

    &:first-of-type {
      margin-top: 16px;
      margin-bottom: 24px;
    }

    &:last-of-type {
      margin-bottom: 32px;
    }
  }
}

.notice-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  margin-top: 154px;
  border-top: 1px solid #d4d4d4;
}

.notice-prev {
  color: #da261d;
  cursor: pointer;
}

.notice-next {
  color: #a6a6a6;
}

.notice-right {
  .content {
    background-color: #fff;
    margin-right: 12px;
    padding: 24px 24px 400px;
    box-sizing: border-box;
    font-size: 16px;

    .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 18px;
    }
  }
}

.notice-other-time-1,
.notice-other-time-2 {
  padding: 8px 0;
  color: #a6a6a6;
  border-bottom: 1px solid #d4d4d4;
}

.notice-other-2 {
  margin-top: 32px;
}
</style>
